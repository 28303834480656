import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ArrowBack,
  Collections,
  Delete,
  Edit,
  Public,
  PublicOff,
  ScheduleSend,
  Workspaces,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { BehaviorQuickstartModal } from "../../../components/modals/BehaviorQuickstartModal";
import { CollectionDeleteModal } from "../../../components/modals/CollectionDeleteModal";
import { CollectionUpdateModal } from "../../../components/modals/CollectionUpdateModal";
import {
  collectionItemCount,
  collectionVisibility,
} from "../../../components/utils/collectionUtils";
import { UserContext } from "../../../contexts/UserContext";

const i18n = {
  share: "Start Sharing",
  action: "Share this collection on your socials every day! ",
};

export const Header = ({ collection }) => {
  const navigate = useNavigate();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const { hasUser } = useContext(UserContext);
  const { cover, title, items, public: isPublic } = collection;

  // Collection share template
  const template = {
    name: collection.title,
    definition: {
      days_between_posts: 0,
      meta: { collection_id: collection.id },
      per_day: 3,
      schedule_config: true,
      shareable_class: "ItemShare",
    },
  };

  const belongsToUser = () => {
    return hasUser() && collection && collection.editable;
  };

  const renderAvatar = () => {
    if (cover) {
      return (
        <Avatar sx={{ width: 60, height: 60 }} variant="rounded" src={cover} />
      );
    } else {
      return (
        <Avatar sx={{ width: 60, height: 60 }} variant="rounded">
          <Collections />
        </Avatar>
      );
    }
  };

  return (
    <Box>
      {hasUser() && (
        <IconButton
          sx={{ marginTop: -5 }}
          size="small"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
      )}

      {!belongsToUser() && (
        <Paper
          square={false}
          sx={{
            background: "linear-gradient(45deg, #8d10eb 30%, #5d099c 90%)",
            display: { md: "none", xs: "block" },
            mb: 2,
            pr: 2,
            pl: 2,
            pt: 1,
            pb: 1,
          }}
        >
          <Typography>
            {i18n.action}
            <Button
              sx={{ color: "white" }}
              variant="outlined"
              startIcon={<ScheduleSend />}
              onClick={() => setIsShareOpen(true)}
            >
              {i18n.share}
            </Button>
          </Typography>
        </Paper>
      )}

      <Box display="flex" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ marginBottom: 4 }}
        >
          {renderAvatar()}

          <Stack direction="column" spacing={0} sx={{ paddingLeft: 1 }}>
            <Typography variant="h4">{title}</Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: grey[600] }}
            >
              {isPublic ? (
                <Public fontSize="small" />
              ) : (
                <PublicOff fontSize="small" />
              )}
              <Typography sx={{ paddingRight: 1 }} variant="body2">
                {collectionVisibility(isPublic)}
              </Typography>

              <Workspaces fontSize="small" />
              <Typography variant="body2">
                {collectionItemCount(items)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box>
          {!belongsToUser() && (
            <Paper
              square={false}
              sx={{
                background: "linear-gradient(45deg, #8d10eb 30%, #5d099c 90%)",
                display: { md: "block", xs: "none" },
                pr: 1,
                pl: 2,
                pt: 1,
                pb: 1,
              }}
            >
              <Typography>
                {i18n.action}
                <Button
                  sx={{ color: "white" }}
                  variant="outlined"
                  startIcon={<ScheduleSend />}
                  onClick={() => setIsShareOpen(true)}
                >
                  {i18n.share}
                </Button>
              </Typography>
            </Paper>
          )}

          {belongsToUser() && (
            <>
              <Button
                sx={{
                  mr: 1,
                  background:
                    "linear-gradient(45deg, #8d10eb 30%, #5d099c 90%)",
                }}
                variant="outlined"
                onClick={() => setIsShareOpen(true)}
              >
                <ScheduleSend />
              </Button>
              <Button
                sx={{ mr: 1 }}
                variant="outlined"
                onClick={() => setIsEditOpen(true)}
              >
                <Edit />
              </Button>
              <Button variant="outlined" onClick={() => setIsDeleteOpen(true)}>
                <Delete />
              </Button>
            </>
          )}
        </Box>
      </Box>

      <BehaviorQuickstartModal
        template={template}
        open={isShareOpen}
        onClose={() => setIsShareOpen(false)}
      />

      {belongsToUser() && (
        <>
          <CollectionUpdateModal
            collection={collection}
            open={isEditOpen}
            onClose={() => setIsEditOpen(false)}
          />
          <CollectionDeleteModal
            open={isDeleteOpen}
            collection={collection}
            onClose={() => setIsDeleteOpen(false)}
          />
        </>
      )}
    </Box>
  );
};
