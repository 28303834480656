import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Link,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import ProgressLoader from "../utils/ProgressLoader";
import { truncate } from "../utils/utils";
import { UserContext } from "../../contexts/UserContext";
import ContentCreateModal from "../modals/ContentCreateModal";
import { useContentDelete } from "../../hooks/useContentDelete";

const i18n = {
  yesButton: "yes",
  noButton: "no",
  editLabel: "edit",
  deleteLabel: "delete",
  deleteConfirm: "Delete forever?",
};

const ContentCard = ({ content, collection, onClick, page }) => {
  const { user: cookieUser, hasUser } = useContext(UserContext);

  const { call: deleteContent, isFetching: isDeleting } = useContentDelete({
    enabled: hasUser(),
  });
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  const [isEditOpen, setEditOpen] = useState(false);

  const { user, title, url, media } = content;

  const iconBgColor = "rgba(0,0,0,0.6)";

  const belongsToUser = () => {
    return hasUser() && collection && collection.editable;
  };

  const handleMenuOpen = (event) => {
    setPopperAnchorEl(event.currentTarget);
    setPopperOpen((prevState) => !prevState);
  };

  const handleDelete = () => {
    setPopperOpen(false);
    deleteContent(content.id, collection.id, page);
  };

  const handleEdit = () => {
    setPopperOpen(false);
    setEditOpen(true);
  };

  const renderDeletePopper = () => {
    return (
      <Popper open={popperOpen} anchorEl={popperAnchorEl}>
        <Paper sx={{ padding: 1.5 }}>
          <Typography variant="body1">{i18n.deleteConfirm}</Typography>
          <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
            <Button
              aria-label={i18n.noButton}
              onClick={handleMenuOpen}
              size="xs"
              sx={{ height: 25 }}
              variant="outlined"
            >
              {i18n.noButton}
            </Button>
            <Button
              aria-label={i18n.noButton}
              color="error"
              onClick={handleDelete}
              size="xs"
              sx={{ height: 25 }}
              variant="contained"
            >
              {i18n.yesButton}
            </Button>
          </Stack>
        </Paper>
      </Popper>
    );
  };

  const renderContent = () => {
    return (
      <CardContent>
        <Typography
          variant="body2"
          sx={{ marginTop: media || !belongsToUser() ? 0 : 3 }}
        >
          {truncate(title, 50)}
        </Typography>
        {url && (
          <Typography variant="body2">
            <Link underline="none" href={url} target="_blank">
              {truncate(url, 21)}
            </Link>
          </Typography>
        )}
      </CardContent>
    );
  };

  const renderButtons = () => {
    return (
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        spacing={0.5}
        sx={{
          padding: 0.5,
          width: { sm: 180, xs: "90%" },
          position: "absolute",
        }}
      >
        <IconButton
          aria-label={i18n.editLabel}
          onClick={handleEdit}
          size="small"
          sx={{ backgroundColor: iconBgColor }}
        >
          <Edit sx={{ fill: "white" }} fontSize="inherit" />
        </IconButton>

        <IconButton
          aria-label={i18n.deleteLabel}
          onClick={handleMenuOpen}
          size="small"
          sx={{ backgroundColor: iconBgColor }}
        >
          {isDeleting ? (
            <ProgressLoader size={18} />
          ) : (
            <Delete sx={{ fill: "white" }} fontSize="inherit" />
          )}
        </IconButton>
      </Stack>
    );
  };

  return (
    <Card
      sx={{
        marginRight: { sm: 2, xs: 0 },
        marginBottom: 2,
        width: { sm: 180, xs: "100%" },
      }}
      raised
    >
      {belongsToUser() && renderButtons()}
      {media && (
        <CardMedia
          onClick={onClick}
          sx={{ height: 180 }}
          component="img"
          image={media.xs}
        />
      )}
      {renderContent()}

      {belongsToUser() && renderDeletePopper()}
      {belongsToUser() && (
        <ContentCreateModal
          open={isEditOpen}
          collection={collection}
          content={content}
          onClose={() => setEditOpen(false)}
        />
      )}
    </Card>
  );
};

export default ContentCard;
